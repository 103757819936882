.faq-section {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-section h2 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-question {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
  transition: background-color 0.3s;
}

.faq-question:hover {
  background-color: #f0f0f0;
}

.faq-toggle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  transition: transform 0.3s;
}

.faq-toggle.open {
  transform: rotate(45deg);
}

.faq-answer {
  padding: 1rem;
  background-color: #f9f9f9;
  border-top: 1px solid #e1e1e1;
  font-size: 1rem;
  color: #555;
}
