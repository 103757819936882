.table-container {
  position: relative;
  max-height: 500px;
  overflow: scroll;
  margin: 0 10px;
  float: left;
  width: 31%;
  padding: 0px;
  font-size: 13px;
  margin-bottom: 80px;
}

/* MAKE LEFT COLUMN FIXEZ */
tr > :first-child {
  position: -webkit-sticky;
  position: sticky;
  background: #bed0d3;
  color: black;
  left: 0;
}

thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background: hsl(180,75%,30%);
  color: white;
  font-size: 14px;
}

thead th:first-child {
  left: 0;
  z-index: 3;
}

tfoot {
  position: -webkit-sticky;
  bottom: 0;
  z-index: 2;
}

tfoot td {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background: hsl(10, 25%, 35%);
  height: 50px;
}

tfoot td:first-child {
  z-index: 3;
}

tbody {
  overflow: scroll;
  height: 50px;
}

* {
  box-sizing: border-box;
}

.row {
  margin:auto;
  padding: 10px 30px;
  display: flex;
}

.column {
  flex: 1;
  padding: 0 10px;
  position: relative;
}

.column:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  cursor: col-resize;
}

.row:last-child .column:after {
  content: none;
}

.row:after {
  content: "";
  display: block;
  height: 5px;
  background-color: #ccc;
  cursor: row-resize;
  margin-top: 10px;
}

.row:last-child:after {
  content: none;
}


tr:nth-child(even) {
  background-color: #f2f2f2;
}