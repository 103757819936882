.sentiment-analysis-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.content-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.main-text, .conclusion-text {
    margin: 15px 0;
    line-height: 1.6;
    color: #555;
}

.conclusion-text {
    font-weight: bold;
    text-align: center;
    color: #1a237e; /* Optional: change this color to suit your design */
}
